@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-Bold.ttf");
    src: local("Euclid Circular B Bold"), local("EuclidCircularB-Bold"),
        url("EuclidCircularB-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-RegularItalic.ttf");
    src: local("EuclidCircularB-RegularItalic"),
        url("EuclidCircularB-RegularItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-Medium.ttf");
    src: local("EuclidCircularB-Medium"),
        url("EuclidCircularB-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-SemiboldItalic.ttf");
    src: local("EuclidCircularB-SemiboldItalic"),
        url("EuclidCircularB-SemiboldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-BoldItalic.ttf");
    src: local("EuclidCircularB-BoldItalic"),
        url("EuclidCircularB-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-MediumItalic.ttf");
    src: local("EuclidCircularB-MediumItalic"),
        url("EuclidCircularB-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-Semibold.ttf");
    src: local("EuclidCircularB-Semibold"),
        url("EuclidCircularB-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-Light.ttf");
    src: local("EuclidCircularB-Light"),
        url("EuclidCircularB-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-Regular.ttf");
    src: local("EuclidCircularB-Regular"),
        url("EuclidCircularB-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Euclid Circular B";
    src: url("EuclidCircularB-LightItalic.ttf");
    src: local("EuclidCircularB-LightItalic"),
        url("EuclidCircularB-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Goodall Pro";
    src: url("goodall-variable-italic-pro_1.ttf");
    src: local("goodall-variable-italic-pro_1"),
        url("goodall-variable-italic-pro_1.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Goodall Pro";
    src: url("goodall-variable-italic-pro_2.ttf");
    src: local("goodall-variable-italic-pro_2"),
        url("goodall-variable-italic-pro_2.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Goodall Pro";
    src: url("goodall-variable-italic-pro.ttf");
    src: local("goodall-variable-italic-pro"),
        url("goodall-variable-italic-pro.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}
